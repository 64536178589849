<template>
    <bs-popup id="mapping-price-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Mapping Price Per Customer Location</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="code" class="form-label">Ship To</label>
                <bs-plain-text v-model="model.shipToLabel" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Location</label>
                <bs-plain-text v-model="model.locationCode" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Price in Sales Unit (USD)</label>
                <bs-plain-number v-model="model.price" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Sales Unit</label>
                <bs-plain-text v-model="model.unitCode" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Valid From</label>
                <div>
                    <view-date v-model="model.validFrom" />
                </div>
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Valid To</label>
                <div>
                    <view-date v-model="model.validUntil" />
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" v-show="isAuthEdit" @click="editData"><i class="bi bi-pencil-square"></i> Edit</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import MappingPriceService from "@/services/mapping-price-service.js";
    import SecurityService from "@/services/security-service.js";
export default {
    data: function() {
        return {
            id: null,
            model: MappingPriceService.default(),
            errorMessages: null,
            isAuthEdit: false
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.id = id;
        this.loadData();
        this.checkRole();
    },
    methods: {
        async loadData() {
            let response = await MappingPriceService.getObject(this.id);
            this.model = response.data;
            if (response.status) 
            {
                this.errorMessages = null;
            }
            else 
            {
                this.errorMessages = response.errorMessages;
            }
        },
        onPopupClosed() {
            this.$router.push('/mapping-price');
        },
        close() {
            this.$refs.form.closePopup();
        },
        editData() 
        {
            this.$refs.form.closePopup();
            this.$router.push(`/mapping-price/edit/${this.id}`);
        },
        async checkRole() {
            const securityService = new SecurityService();

            if (await securityService.allowAccessAdmin("MAPPINGPRICE", "UPDATE")) {
                this.isAuthEdit = true;
            } else {
                this.isAuthEdit = false;
            }
        }
    }
}
</script>